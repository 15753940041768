import React, { Component } from "react"
import PropTypes from "prop-types"

import iconAlert from "../../assets/images/icon-alert.png"
import { Table } from "semantic-ui-react"
import moment from "moment"

class VehicleRecall extends Component {
  static propTypes = {
    recall: PropTypes.object.isRequired,
    vehicle: PropTypes.object.isRequired
  }

  render() {
    const { recall } = this.props
    return (
      <Table.Row>
        <Table.Cell singleLine>{moment(recall.report_received_at).format("MMMM YYYY")}</Table.Cell>
        <Table.Cell>{recall.component}</Table.Cell>
        <Table.Cell>{recall.summary}</Table.Cell>
      </Table.Row>
    )
  }
} // class VehicleRecall

export default VehicleRecall
