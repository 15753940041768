import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { selectOptions } from "../../helpers/serviceHelpers"
import * as shopHelpers from "../../helpers/shopHelpers"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import Button from "../../elements/Button"
import Select from "../../elements/Select"

import AddServiceRow from "./AddServiceRow"
import TextField from "../../elements/TextField"
import { Segment, Form, TextArea, Container, Header, Grid, Divider, Input } from "semantic-ui-react"

import DatePicker from "react-datepicker"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons"
import { Date_Format } from "../../constants/years"
import { FR_LOCALE } from "../../constants/users"
import { withTranslation } from "react-i18next"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"

let current = {
  odometer: null,
  odometer_units: "miles",
  name: "",
  service_id: null,
  price: null,
  position: null,
  rowIndex: 0
}

class MaintenanceHistoryForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    services: PropTypes.array.isRequired,
    shops: PropTypes.array.isRequired,
    vehicles: PropTypes.array.isRequired,
    isSubmitting: PropTypes.bool,
    gotoShopSearch: PropTypes.func.isRequired
  }

  static defaultProps = {
    isSubmitting: false
  }

  state = {
    ...this.props.previousFormData,
    serviceRows: [{ ...current }]
  }

  isAnyServiceNull(serviceRows) {
    return serviceRows.map((row) => row.service_id).includes(null)
  }

  isAnyServicePriceNull(serviceRows) {
    return serviceRows.some((servicerRow) => servicerRow.price === "" || servicerRow.price === null)
  }

  allowSubmit() {
    const { isSubmitting } = this.props
    const {
      shop_id,
      vehicle_id,
      odometer,
      date_of_completion,
      custom_shop_name,
      serviceRows
    } = this.state
    return (
      !isSubmitting &&
      vehicle_id &&
      date_of_completion &&
      !this.isAnyServiceNull(serviceRows) &&
      (shop_id || custom_shop_name) &&
      !this.isAnyServicePriceNull(serviceRows) &&
      odometer
    )
  }

  formattedFormData() {
    let {
      vehicle_id,
      odometer,
      date_of_completion,
      shop_id,
      custom_shop_name,
      po_number,
      customer_comments,
      serviceRows,
      date_of_completion_pretty
    } = this.state
    return {
      vehicle_id: parseInt(vehicle_id),
      shop_id,
      custom_shop_name,
      odometer,
      date_of_completion,
      date_of_completion_pretty,
      po_number,
      customer_comments,
      vehicle_services: serviceRows.map((row) => {
        return {
          date_of_completion,
          date_of_completion_pretty,
          odometer: odometer,
          odometer_units: row.odometer_units,
          name: row.name,
          price: row.price,
          serviceDefinition: null,
          service_definition_id: row.service_id,
          position: row.position
        }
      })
    }
  }

  onChange(field, value) {
    if (field === "date_of_completion") {
      this.setState({
        [field]: value === "" ? undefined : value,
        [`${field}_pretty`]: value === "" ? undefined : value.format("YYYY-MM-DD HH:mm:ss")
      })
    } else {
      this.setState({ [field]: value === "" ? undefined : value })
    }
  }

  onVehicleChange(value) {
    if (value) {
      this.setState({
        vehicle_id: value,
        vehicle: this.props.vehicles.find((s) => s.id == parseInt(value))
      })
    }
  }

  onServiceChange = (value, rowIndex) => {
    if (value) {
      let serviceRows = [...this.state.serviceRows]
      let selectedService = this.props.services.find((s) => s.id == parseInt(value))

      serviceRows.forEach((serviceRow) => {
        if (serviceRow.rowIndex === rowIndex) {
          return Object.assign(serviceRow, {
            service_id: selectedService.id,
            name: selectedService.name,
            service: selectedService
          })
        }
      })

      this.setState({ ...this.state, serviceRows: serviceRows })
    }
  }

  handleCurrentChange = (field, value, rowIndex) => {
    let { serviceRows } = this.state

    serviceRows.forEach((serviceRow) => {
      if (serviceRow.rowIndex === rowIndex) {
        return Object.assign(serviceRow, { [field]: value })
      }
    })

    this.setState({ serviceRows })
  }

  renderAddServiceRows(serviceRows) {
    if (serviceRows.length > 0) {
      return serviceRows.map((serviceRow, index) => {
        return (
          <AddServiceRow
            key={index}
            serviceRow={serviceRow}
            services={this.props.services}
            onServiceChange={this.onServiceChange}
            handleCurrentChange={this.handleCurrentChange}
            handleRemove={this.handleRemove}
            {...this.state}
          />
        )
      })
    }
  }

  addMoreService() {
    let { serviceRows } = this.state
    const lastRow = serviceRows.slice(-1)[0]
    let rowIndex = lastRow ? lastRow.rowIndex + 1 : 0

    serviceRows = [...serviceRows, { ...current, rowIndex }]
    this.setState({ serviceRows })
  }

  handleRemove = (rowIndex) => {
    let { serviceRows } = this.state
    serviceRows = [...serviceRows.filter((service) => service.rowIndex !== rowIndex)]
    this.setState({ serviceRows })
  }

  render() {
    const { onSubmit, shops, vehicles, gotoShopSearch, language, t } = this.props
    const isEnabled = this.allowSubmit()
    const {
      shop_id,
      vehicle_id,
      odometer,
      date_of_completion,
      po_number,
      custom_shop_name,
      customer_comments,
      serviceRows
    } = this.state

    return (
      <div>
        <Segment padded raised>
          <Form>
            <Grid stackable columns={2}>
              <Grid.Column width={7}>
                <Header as="h3">{t("common:addMaintenanceHistoryLabel")}</Header>
                <Divider />
                <Form.Field required style={{ display: "flex" }}>
                  <Select
                    onChange={(event) => this.onVehicleChange(event.target.value)}
                    options={vehicleHelpers.selectOptions(vehicles)}
                    placeholder={t("selectVehicleLabel")}
                    defaultValue={vehicle_id}
                    value={vehicle_id}
                  />
                  <label></label>
                </Form.Field>
                <Divider hidden />
                <TextField
                  onChange={(event) => this.onChange("odometer", event.target.value)}
                  placeholder={t("enterOdometerLabel")}
                  value={odometer}
                  required={true}
                />
                <Divider hidden />

                <span className="link" onClick={() => gotoShopSearch(this.formattedFormData())}>
                  {t("searchShopLabel")}!
                </span>
                <Form.Field
                  required={custom_shop_name ? false : true}
                  style={{ display: "flex", width: custom_shop_name ? "99%" : "100%" }}
                >
                  <Select
                    onChange={(event) => this.onChange("shop_id", event.target.value)}
                    options={[{ value: undefined, label: t("manualEnterShopLabel") }].concat(
                      shopHelpers.selectOptions(shops)
                    )}
                    placeholder={
                      shops.length == 0 ? t("noFavoriteShopsLabel") : t("selectShopLabel")
                    }
                    defaultValue={shop_id}
                    value={shop_id}
                  />
                  <label> </label>
                </Form.Field>
                <Divider hidden />

                <TextField
                  onChange={(event) => this.onChange("custom_shop_name", event.target.value)}
                  placeholder={t("CustomShopNameLabel")}
                  value={custom_shop_name}
                  required={shop_id ? false : true}
                />
              </Grid.Column>

              <Grid.Column width={7}>
                <Header as="h3">&nbsp; </Header>
                <Divider />

                <TextField
                  onChange={(event) => this.onChange("po_number", event.target.value)}
                  placeholder={t("poNumberLabel")}
                  value={po_number}
                />

                <Divider hidden />

                <TextField
                  onChange={(event) => this.onChange("customer_comments", event.target.value)}
                  placeholder={t("customerCommentsLabel")}
                  value={customer_comments}
                />

                <Divider hidden />
                <Divider hidden />
                <Form.Field required style={{ display: "flex" }}>
                  <DatePicker
                    selected={date_of_completion}
                    onChange={(value) => this.onChange("date_of_completion", value)}
                    maxDate={moment()}
                    dateFormat={
                      isFleetMaintenanceHubCanada()
                        ? Date_Format.YYYY_MM_DD
                        : Date_Format.MM_DD_YYYY
                    }
                    placeholderText={t("dateOfCompletionLabel")}
                    customInput={<Input value={date_of_completion} />}
                  />
                  <label></label>
                </Form.Field>
              </Grid.Column>
            </Grid>

            <Header as="h3">{t("servicesLabel")} </Header>
            <Divider />

            {this.renderAddServiceRows(serviceRows)}

            {
              <Grid centered>
                <Grid.Row>
                  <div className={"submit-container"}>
                    <Button
                      label={
                        <>
                          <span>{t("addMoreLabel")} </span>
                          <FontAwesomeIcon icon={faPlus} style={{ marginTop: "-10px" }} />
                        </>
                      }
                      onClick={() => this.addMoreService()}
                    />
                  </div>
                </Grid.Row>
              </Grid>
            }

            <Grid centered>
              <Grid.Row>
                <div className={"submit-container"}>
                  <Button
                    disabled={!isEnabled}
                    label={t("common:saveLabel")}
                    onClick={() => onSubmit(this.formattedFormData())}
                  />
                </div>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
      </div>
    )
  }
} // class MaintenanceHistoryForm

const mapStateToProps = (state) => {
  return {
    services: state.services.services,
    vehicles: state.vehicles.vehicles,
    shops: state.shops.shops.length > 0 ? state.shops.shops : state.shops.favoriteShops
  }
}

export default connect(mapStateToProps)(
  withTranslation("maintenanceHistory")(MaintenanceHistoryForm)
)
