export const initialState = {
  snoozeMaintenanceReminder: []
}

export default function (state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case "VEHICLE_UPCOMING_INTERVAL_SNOOZE_SUCCEEDED":
      return {
        ...state,
        snoozeMaintenanceReminder: payload.vehicleId
      }
    case "VEHICLE_UPCOMING_INTERVAL_SNOOZED_DATE_LOAD_SUCCEEDED":
      return {
        ...state,
        snoozedReminderDates: payload.snoozedReminderDates
      }
    default:
      return state
  }
}
