import React, { Component } from "react"
import PropTypes from "prop-types"

import * as serviceHelpers from "../../helpers/serviceHelpers"
import * as userHelpers from "../../helpers/userHelpers"
import {
  AUTO_APPROVE_USER_ID,
  autoApproveUser,
  ALL_VEHICLES_ID,
  allVehiclesVehicle
} from "../../constants/users"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import { Link } from "react-router-dom"
import { Select, Input, Table } from "semantic-ui-react"
import { navigationPaths } from "../../constants/paths"
import { sortByAttr } from "../../helpers/arrayHelpers"
import CollapsibleList from "../shared/CollapsibleList"
import { withTranslation } from "react-i18next"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import AlertModal from "../shared/AlertModal"
import { MAINTENANCE_INDEX } from "../../constants/application"

class MaintenancePolicy extends Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    policy: PropTypes.object.isRequired,
    services: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    vehicles: PropTypes.array.isRequired,

    displayButtons: PropTypes.bool,
    isEditing: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    allowActions: PropTypes.bool
  }

  static defaultProps = {
    displayButtons: true,
    isEditing: false,
    isSubmitting: false,
    allowActions: false
  }

  state = {
    formData: {
      amount: this.props.policy.amount,
      id: this.props.policy.id,
      fleet_id: this.props.policy.fleet_id,
      service_definition_ids: this.props.policy.service_definition_ids,
      user_ids: this.userIdsWithAutoApprove(),
      vehicle_ids: this.props.policy.vehicle_ids,
      all_vehicles: this.props.policy.all_vehicles
    },
    openAlertModal: false,
    policyId: null
  }

  // NOTE: Updates component formData after adding (via prepending) a new policy.
  // NOTE: Resets component formData after canceling an edit.
  componentDidUpdate(prevProps, prevState) {
    const {
      id,
      amount,
      service_definition_ids,
      user_ids,
      vehicle_ids,
      auto_approvable,
      all_vehicles
    } = this.props.policy

    if (this.props !== prevProps) {
      const updatedFormData = {
        id: id,
        amount: amount,
        service_definition_ids: service_definition_ids ? service_definition_ids : [],
        user_ids: auto_approvable ? [AUTO_APPROVE_USER_ID] : user_ids,
        vehicle_ids: all_vehicles ? [] : vehicle_ids,
        auto_approvable: auto_approvable,
        all_vehicles: all_vehicles
      }

      this.setState({ formData: updatedFormData })
    }
  }

  userIdsWithAutoApprove() {
    const { auto_approvable, user_ids } = this.props.policy

    return auto_approvable ? [AUTO_APPROVE_USER_ID] : user_ids
  }

  allowSubmit() {
    const { isSubmitting } = this.props

    const { user_ids } = this.state.formData

    return !isSubmitting && user_ids.length !== 0
  }

  formattedFormData() {
    let { formData } = this.state

    if (formData.user_ids.includes(AUTO_APPROVE_USER_ID)) {
      formData.user_ids = []
      formData.auto_approvable = true
    } else {
      formData.auto_approvable = false
    }

    return formData
  }

  confirmDelete(id) {
    this.setState({ openAlertModal: true, policyId: id })
  }

  async onChange(field, value) {
    let updatedFormData = { ...this.state.formData }
    updatedFormData[field] = value
    this.setState({ formData: updatedFormData })
  }

  async onVehicleSelectChange(field, values) {
    if (values.includes(ALL_VEHICLES_ID)) {
      await this.onChange("all_vehicles", true)
    } else {
      await this.onChange("all_vehicles", false)
      this.onChange("vehicle_ids", values)
    }
  }

  onUserSelectChange(field, values) {
    let newValues
    if (values.length === 1) {
      newValues = values
    } else if (values[0] === AUTO_APPROVE_USER_ID) {
      newValues = [values[1]]
    } else if (values[values.length - 1] === AUTO_APPROVE_USER_ID) {
      newValues = [AUTO_APPROVE_USER_ID]
    } else {
      newValues = values
    }

    this.onChange(field, newValues)
  }

  renderEditColumn() {
    const { allowActions, displayButtons, onEdit, t } = this.props

    const { id } = this.state.formData

    if (allowActions) {
      if (displayButtons) {
        return (
          <React.Fragment>
            <span className="link" onClick={() => onEdit(id)}>
              {t("editLabel")}
            </span>{" "}
            &nbsp;
            {id !== null && (
              <span className="link" onClick={() => this.confirmDelete(id)}>
                {t("deleteLabel")}
              </span>
            )}
          </React.Fragment>
        )
      }
    } else {
      return (
        <React.Fragment>
          <Link className="link" to={navigationPaths.settingsIndex()}>
            {t("editLabel")}
          </Link>
          &nbsp;
          <Link className="link" to={navigationPaths.settingsIndex()}>
            {t("deleteLabel")}
          </Link>
        </React.Fragment>
      )
    }
  }

  handleModalState = () => {
    this.setState({ openAlertModal: false })
  }

  render() {
    const { isEditing, services, users, vehicles, t, onDelete } = this.props
    const { openAlertModal, policyId, formData } = this.state
    const { amount, service_definition_ids, user_ids, vehicle_ids, all_vehicles } = formData
    const vehicleNames = (vehicle_ids || [])
      .map((vehicle_id) => {
        const vehicle = vehicles.find((v) => v.id === vehicle_id)
        return !vehicle
          ? false
          : vehicleHelpers.formattedName(vehicle, {
              displayLicensePlateNumber: true,
              displayOtherID: true
            })
      })
      .filter(Boolean)

    const alertModal = openAlertModal ? (
      <AlertModal
        openAlertModal={openAlertModal}
        hideModal={this.handleModalState}
        idToDelete={policyId}
        onDelete={onDelete}
        modelFrom={MAINTENANCE_INDEX}
      />
    ) : null
    if (isEditing)
      return (
        <Table.Row>
          <Table.Cell>
            <Select
              search
              disabled={!isEditing}
              onChange={(_, data) => this.onUserSelectChange("user_ids", data.value)}
              options={userHelpers.selectOptions([autoApproveUser].concat(users), {
                semantic: true
              })}
              placeholder={t("selectUsersLabel")}
              value={user_ids}
              multiple
            />
          </Table.Cell>
          <Table.Cell>
            <Select
              search
              disabled={!isEditing}
              onChange={(_, data) => this.onVehicleSelectChange("vehicle_ids", data.value)}
              options={vehicleHelpers.selectOptions([allVehiclesVehicle].concat(vehicles), t, {
                displayLicensePlateNumber: true,
                displayOtherID: true,
                semantic: true
              })}
              placeholder={t("selectVehiclesLabel")}
              value={all_vehicles ? [ALL_VEHICLES_ID] : vehicle_ids}
              multiple
            />
          </Table.Cell>
          <Table.Cell>
            <Input
              disabled={!isEditing}
              onChange={(_, event) => this.onChange("amount", event.value)}
              placeholder={t("enterDollarsLabel")}
              value={amount}
            />
          </Table.Cell>
          <Table.Cell>
            <Select
              search
              disabled={!isEditing}
              onChange={(_, data) => this.onChange("service_definition_ids", data.value)}
              options={serviceHelpers.selectOptions(sortByAttr(services, "name"), {
                semantic: true
              })}
              placeholder={t("selectServicesLabel")}
              value={service_definition_ids}
              multiple
            />
          </Table.Cell>
          <Table.Cell singleLine>
            <span
              className="link"
              disabled={!this.allowSubmit()}
              onClick={() => this.props.onSubmit(this.formattedFormData())}
            >
              {t("saveLabel")}
            </span>{" "}
            &nbsp;
            <span className="link" onClick={() => this.props.onCancel(this.state.formData.id)}>
              {t("cancelLabel")}
            </span>
          </Table.Cell>
        </Table.Row>
      )
    else
      return (
        <>
          <Table.Row>
            <Table.Cell>
              {userHelpers
                .selectOptions([autoApproveUser].concat(users), { semantic: true })
                .filter((l) => user_ids && user_ids.indexOf(l.value) !== -1)
                .map((l) => l.text)
                .join(", ")}
            </Table.Cell>
            <Table.Cell>
              {all_vehicles && t("allVehiclesLabel")}
              {!all_vehicles && <CollapsibleList items={vehicleNames} t={t} />}
            </Table.Cell>
            <Table.Cell>
              {isFleetMaintenanceHubCanada() && amount
                ? `$${parseFloat(amount).toFixed(2)} CAD`
                : amount
                ? `$${parseFloat(amount).toFixed(2)}`
                : ""}
            </Table.Cell>
            <Table.Cell>
              {serviceHelpers
                .selectOptions(services, { semantic: true })
                .filter(
                  (l) => service_definition_ids && service_definition_ids.indexOf(l.value) !== -1
                )
                .map((l) => l.text)
                .join(", ")}
            </Table.Cell>
            <Table.Cell singleLine>{this.renderEditColumn()}</Table.Cell>
          </Table.Row>
          {alertModal}
        </>
      )
  }
} // class MaintenancePolicy

export default withTranslation("common")(MaintenancePolicy)
