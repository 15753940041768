import React, { useState } from "react"
import { withTranslation } from "react-i18next"

const CollapsibleList = ({
  items,
  alwaysVisibleCount = 5,
  applySort = true,
  t,
  showAllIcons,
  approval_history = false
}) => {
  const [showAllItems, setShowAllItems] = useState(false)
  const itemsCount = showAllItems ? (items && items.length) || 0 : alwaysVisibleCount
  const sortedItems = applySort ? items.sort() : items

  return (
    <>
      {sortedItems &&
        sortedItems.slice(0, itemsCount).map((item, index) => {
          if (item) {
            const length = item.length || 0
            return (
              <div
                style={{ margin: "10px", height: item.length > 51 ? "auto" : "auto" }}
                key={`collapsible-item-${index}`}
              >
                {item}
              </div>
            )
          }
        })}
      {items && items.length > alwaysVisibleCount && (
        <div
          style={{ margin: "10px" }}
          className="link"
          onClick={() => {
            setShowAllItems(!showAllItems)
            showAllIcons(!showAllItems)
          }}
        >
          {`${t("seeLabel")} ${showAllItems ? `${t("lessLabel")}` : `${t("moreLabel")}`}`}
        </div>
      )}
    </>
  )
}

export default withTranslation("common")(CollapsibleList)
