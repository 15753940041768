import React from "react"
import ReactDOM from "react-dom"
import * as registerServiceWorker from "./registerServiceWorker"
import NewRelicInjector from "./components/shared/NewRelicInjector"
import SentryInjector from "./components/shared/SentryInjector"
import StylesheetInjector from "./components/shared/StylesheetInjector"
import App from "./App"
import { SplitFactory } from "@splitsoftware/splitio-react"
import sdkConfig from "./sdkConfig"

SentryInjector()
StylesheetInjector()
NewRelicInjector()

ReactDOM.render(
  <SplitFactory config={sdkConfig} updateOnSdkTimedout={true}>
    {({ factory, isReady, isTimedout, lastUpdate }) => {
      return <App />
    }}
  </SplitFactory>,
  document.getElementById("root")
)
registerServiceWorker.unregister()
