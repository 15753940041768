export const applicationIdentifier = "fleetmd"
export const FLEET = "fleet"
export const FMC = "fmc"

// subdomains
export const CARVANA = "carvana"
export const FLEET_MAINTENANCE_HUB = "shell"
export const FLEET_MAINTENANCE_HUB_CANADA = "shellcanada"
export const FLEET_AMERICA = "fleetamerica"
export const FLEET_ADVISE = "fleetadvise"
export const ZUBIE = "zubie"
export const FLEETCOR = "fleetcor"
export const FLEETCOR_NAL = "fleetcor_nal"
export const FLEETCOR_NAP = "fleetcor_nap"
export const FUELMAN = "fuelman"
export const NTWTBC = "ntw"

export const VEHICLE_INDEX = "vehicle_index"
export const MAINTENANCE_INDEX = "maintenance_policy"
export const FMC_USER = "fmc_user"
export const FMC_EDIT = "fmc_edit"
export const SETTINGS_USER = "settings_user"
export const PAYMENT = "payment"
export const FIELD_AWAITING_APPROVAL_COUNT = "awaiting_approval_count"
export const IS_DEFAULT = "is_default"

export const MD_HD_VEHICLE_TYPE_ID = 10

export const DOCUMENT_TYPE = {
  DRIVER_LICENSE: "Drivers License",
  VEHICLE_INSURANCE: "Vehicle Insurance",
  REGISTRATION: "Registration",
  OTHER: "Other"
}
