export const SUCCESS = "success"
export const DANGER = "danger"
export const WARNING = "warning"
export const DEFAULT = "default"
export const INFO = "info"
export const AWEWSOM = "awesome"

export const NOTIFICATION_TYPE = Object.freeze({
  SUCCESS: "success",
  DANGER: "danger",
  WARNING: "warning",
  DEFAULT: "default",
  INFO: "info",
  AWEWSOM: "awesome"
})
