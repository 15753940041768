import React, { Component } from "react"
import PropTypes from "prop-types"
import { Form, Input } from "semantic-ui-react"

class TextField extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

    className: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    required: PropTypes.func.isRequired
  }

  static defaultProps = {
    className: "",
    disabled: false,
    placeholder: "",
    error: false,
    type: "text",
    required: false
  }

  type() {
    const { type } = this.props

    return type === "tel" ? "tel" : type === "number" ? "number" : "text"
  }

  render() {
    const { className, disabled, onChange, placeholder, value, error, name, required } = this.props

    return (
      <Form.Field
        required={required}
        style={{ display: required ? "flex" : "block", width: required ? "100%" : "99%" }}
      >
        <Input
          error={error}
          fluid
          className={className}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          type={this.type()}
          value={value}
          name={name}
          min={0}
        />
        {required && <label></label>}
      </Form.Field>
    )
  }
} // class TextField

export default TextField
