import * as arrayHelpers from "./arrayHelpers"
import * as phoneNumberHelpers from "./phoneNumberHelpers"
import {
  ADMIN,
  FMC,
  OWNER,
  FMC_FLEET_ADMIN,
  FMC_FLEET_MANAGER,
  ACCOUNT_OWNER,
  FLEET_MANAGER,
  DRIVER,
  MANAGER,
  ADMIN_FR,
  FMC_FR,
  OWNER_FR,
  FMC_FLEET_ADMIN_FR,
  FMC_FLEET_MANAGER_FR,
  ACCOUNT_OWNER_FR,
  FLEET_MANAGER_FR,
  DRIVER_FR,
  MANAGER_FR
} from "../constants/roles"

export function eligibleForApprovalAndAuthorized(activeOrder) {
  const currentUserCanApprove = activeOrder.can_approve
  return eligibleForApproval(activeOrder) && currentUserCanApprove
}

export function eligibleForApproval(order) {
  return (
    eligibleForApprovalStatuses().includes(order.status) ||
    eligibleForApprovalStatuses().includes(order.state)
  )
}

export function eligibleForApprovalStatuses() {
  return ["awaiting_approval"]
}

export function orderServiceEligibleForApproval(orderService) {
  // TODO: fix our order service statuses so that only 'awaiting_approval' is needed here
  return ["awaiting_approval", "initiated"].includes(orderService.status)
}

export function eligibleForCancelation(order) {
  return eligibleForCancelationStatuses().includes(order.state)
}

export function eligibleForCancelationStatuses() {
  return ["active", "initiated", "initiated_by_user"]
}

export function formattedApprovalContacts(order, users) {
  const contactNames = order.approver_user_ids
    .map((id) => {
      const user = arrayHelpers.findObjectById(users, id)
      if (!user) return null

      const name = user.name
      const number = phoneNumberHelpers.reformat(user.cellPhone) || "N/A"

      return `${name}: ${number}`
    })
    .filter(Boolean)

  return contactNames.join("\n")
}

export function formattedCancelationContacts(order, users) {
  const contactNames = order.cancel_user_ids.map((id) => {
    const user = arrayHelpers.findObjectById(users, id)
    const name = (user && user.name) || "N/A"
    const number = (user && phoneNumberHelpers.reformat(user.cellPhone)) || "N/A"

    return `${name}: ${number}`
  })

  return contactNames.join("\n")
}

const ADMIN_ROLE_NAMES = [
  "Owner",
  "Propriétaire",
  "Manager",
  "Directeur",
  "Admin",
  "Administrateur",
  "FMC",
  "Super Admin",
  "Super administrateur",
  "FMC Fleet Admin",
  "Administrateur de flotte FMC",
  "FMC Fleet Manager",
  "Gestionnaire de flotte FMC",
  "Account Owner",
  "Propriétaire du compte",
  "Fleet Manager",
  "Gestionnaire de flotte"
]
const USER_ROLE_NAMES = [
  "Owner",
  "Propriétaire",
  "Manager",
  "Directeur",
  "Admin",
  "Administrateur",
  "Driver",
  "Conducteur",
  "Super Admin",
  "Super administrateur"
]
export function isOwnerManagerOrAdmin(user) {
  if (user == null || user.roles == null) return false

  return (
    user.roles.find((role) => {
      // user.roles is sometimes an array of names, sometimes role objects- search for either
      return ADMIN_ROLE_NAMES.includes(role) || ADMIN_ROLE_NAMES.includes(role.name)
    }) != null
  )
}

export function checkUserRole(user) {
  if (user == null || user.roles == null) return false

  return (
    user.roles.find((role) => {
      // user.roles is sometimes an array of names, sometimes role objects- search for either
      return USER_ROLE_NAMES.includes(role) || USER_ROLE_NAMES.includes(role.name)
    }) != null
  )
}

const FLEET_MAIN_USERS = ["Owner", "Admin", "FMC", "FMC Fleet Admin"]

export function isOwnerAdminFMCOrFMCADMIN(user) {
  if (user == null || user.roles == null) return false

  return (
    user.roles.find((role) => {
      // user.roles is sometimes an array of names, sometimes role objects- search for either
      return FLEET_MAIN_USERS.includes(role) || FLEET_MAIN_USERS.includes(role.name)
    }) != null
  )
}

// compare arrays:
function isIdentical(arr1, arr2) {
  return arr1.some((element) => {
    return arr2.includes(element)
  })
}

export function checkRoleBaseAccessibility(currentUser, user) {
  if (user == null || user.roles == null) return false
  if (!isIdentical(currentUser.roles, [DRIVER, DRIVER_FR]) && currentUser.id === user.id)
    return true
  else if (isIdentical(currentUser.roles, [FMC, FMC_FLEET_ADMIN, FMC_FR, FMC_FLEET_ADMIN_FR]))
    return true
  else if (isIdentical(currentUser.roles, [ADMIN, ADMIN_FR, OWNER, OWNER_FR])) {
    return user.roles.find((role) => {
      return USER_ROLE_NAMES.includes(role) || USER_ROLE_NAMES.includes(role.name)
    })
  } else if (
    isIdentical(currentUser.roles, [
      MANAGER,
      MANAGER_FR,
      FMC_FLEET_MANAGER,
      FMC_FLEET_MANAGER_FR,
      ACCOUNT_OWNER,
      FLEET_MANAGER
    ])
  ) {
    return user.roles.find((role) => {
      return [DRIVER].includes(role) || [DRIVER].includes(role.name)
    })
  }
}

export function checkRoleBaseAccessibilityDelete(currentUser) {
  if (
    isIdentical(currentUser.roles, [MANAGER, MANAGER_FR, FMC_FLEET_MANAGER, FMC_FLEET_MANAGER_FR])
  )
    return false
  else return true
}

export function checkRoleBaseAccessibilityCreate(currentUser) {
  console.log(currentUser)
  if (
    isIdentical(currentUser.roles, [
      MANAGER,
      MANAGER_FR,
      FMC_FLEET_MANAGER,
      FMC_FLEET_MANAGER_FR,
      DRIVER,
      DRIVER_FR
    ])
  )
    return false
  else return true
}
