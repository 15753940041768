import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"

export function* snoozeMaintenanceReminders(action) {
  try {
    yield put({ type: "VEHICLE_UPCOMING_INTERVAL_SNOOZE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const { vehicleId, value } = action.payload

    const request = {
      url: `/vehicles/${vehicleId}/vehicle_reminders/snooze_reminder`,
      body: { vehicle_id: vehicleId, snoozValue: value }
    }

    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "VEHICLE_UPCOMING_INTERVAL_SNOOZE_SUCCEEDED",
      payload: {
        snoozeMaintenanceReminder: response.body
      }
    })

    const afterRequestData = {
      alertMessage: `Maintenance Reminder Is Snoozed up to ${response.body.reminder_date}`,
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_UPCOMING_INTERVAL_SNOOZE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* snoozeMaintenanceReminders

export function* getSnoozedDateForMaintenanceReminder(action) {
  try {
    yield put({ type: "VEHICLE_UPCOMING_INTERVAL_SNOOZED_DATE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const { vehicleIds } = action.payload

    const request = {
      url: `/vehicles/me/vehicle_reminders/snoozed_date?vehicle_ids=${vehicleIds}`
    }

    const response = yield call(api.utility.get, api.path(request.url))

    apiErrorHelpers.catchErrors(response.body)
    yield put({
      type: "VEHICLE_UPCOMING_INTERVAL_SNOOZED_DATE_LOAD_SUCCEEDED",
      payload: {
        snoozedReminderDates: response.body
      }
    })

    const afterRequestData = {
      alertMessage: `The snoozed date list are ${response.body.vehicle_reminders}`,
      alertType: "success",
      snoozedVehicles: response.body.vehicle_reminders
    }

    if (action.callback) {
      yield call(action.callback, "success", afterRequestData)
    }
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_UPCOMING_INTERVAL_SNOOZED_DATE_LOAD_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* getSnoozedDateForMaintenanceReminder

export default function* maintenanceReminderSaga() {
  yield takeEvery("MAINTENANCE_REMINDER_SNOOZE_SAGA", snoozeMaintenanceReminders)
  yield takeEvery("MAINTENANCE_REMINDER_SNOOZED_DATE_SAGA", getSnoozedDateForMaintenanceReminder)
}
