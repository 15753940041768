import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Table } from "semantic-ui-react"

import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import NoResults from "../../components/shared/NoResults"
import ShopListFilter from "./ShopListFilter"
import ShopListItem from "./ShopListItem"
import { withTranslation } from "react-i18next"

class ShopList extends Component {
  constructor(props) {
    super(props)
    this.setChangedFilter = this.setChangedFilter.bind(this)
    this.state = {
      shopData: [],
      payload: [],
      pageCount: 1
    }
  }

  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    onFavoriteSubmit: PropTypes.func.isRequired,
    shops: PropTypes.array.isRequired,
    previousFormData: PropTypes.object
  }

  setChangedFilter(payload, pageCount) {
    this.setState({ payload: payload, shopData: [], pageCount })
  }

  loadMoreItems = () => {
    this.setState(() => {
      return { pageCount: this.props.pageCount + 1 }
    }, this.afterStateUpdate)
  }

  afterShopFilter = () => {
    this.setState((prevState) => ({
      shopData: [...prevState.shopData, ...this.props.shops]
    }))
  }

  afterStateUpdate = () => {
    const { dispatch } = this.props
    const { payload, pageCount } = this.state
    dispatch({
      type: "SHOPS_FILTER_SAGA",
      payload: { ...payload, pageCount: pageCount },
      callback: this.afterShopFilter
    })
  }

  render() {
    const {
      isLoading,
      isLoadingError,
      onFavoriteSubmit,
      shops,
      searchMode,
      previousFormData,
      asSubForm,
      handleSelectShop,
      disableSubmit,
      language,
      t,
      totalPages,
      pageCount,
      vehicle_type_id
    } = this.props

    const { shopData } = this.state

    const data = shopData.length !== 0 ? shopData : shops
    return (
      <div>
        <LoadingThrobber visible={isLoading} />

        <LoadingError visible={!isLoading && isLoadingError} />

        {searchMode && (
          <div style={isLoading ? { display: "none", height: "0px" } : {}}>
            {asSubForm && <span className="primary-button-color">{t("priceByLocationLabel")}</span>}
            <ShopListFilter
              asSubForm={asSubForm}
              filterShopsParams={this.props.filterShopsParams}
              setChangedFilter={this.setChangedFilter}
              shopListSetState={this.setState}
              vehicle_type_id={vehicle_type_id}
            />
          </div>
        )}

        {!isLoading && !isLoadingError && (
          <div>
            <Table
              stackable
              basic="very"
              width="100%"
              style={{ marginTop: `${searchMode ? 18 : 2}px` }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>{t("shopLabel")}</Table.HeaderCell>
                  {asSubForm && <Table.HeaderCell>{t("priceLabel")}</Table.HeaderCell>}
                  {searchMode && <Table.HeaderCell>{t("distanceLabel")}</Table.HeaderCell>}
                  <Table.HeaderCell>{t("addressLabel")}</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  {!asSubForm && <Table.HeaderCell></Table.HeaderCell>}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {data.map((shop, index) => {
                  return (
                    <ShopListItem
                      key={`shop-${index}`}
                      onFavoriteSubmit={onFavoriteSubmit}
                      shop={shop}
                      searchMode={searchMode}
                      previousFormData={previousFormData}
                      asSubForm={asSubForm}
                      handleSelectShop={handleSelectShop}
                      disableSubmit={disableSubmit}
                      language={language}
                    />
                  )
                })}
              </Table.Body>
            </Table>
            {shops.length ? (
              <button
                className="ui tiny button"
                onClick={this.loadMoreItems}
                disabled={totalPages === 0 || pageCount === totalPages}
              >
                See more
              </button>
            ) : null}
          </div>
        )}

        <NoResults visible={!isLoading && !isLoadingError && shops.length === 0} />
      </div>
    )
  }
} // class ShopList

const mapStateToProps = (state) => ({
  pageCount: state.shops.pageCount
})

export default connect(mapStateToProps, null)(withTranslation("shops")(ShopList))
