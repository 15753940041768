import React, { Component } from "react"
import PropTypes from "prop-types"
import { Form, Dropdown } from "semantic-ui-react"

class Select extends Component {
  // Example options:
  // [
  //    { label: 'Hello', value: 'World' },
  //    { label: 'Alfa', value: 'Beta' }
  // ]

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    defaultValue: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }

  static defaultProps = {
    className: "",
    disabled: false,
    options: [],
    value: ""
  }

  formattedOptionValues() {
    const { options = [] } = this.props

    return options.map((option, index) => {
      return {
        content: (
          <option
            key={index}
            value={option.value}
            title={option.text}
            className={option.value === null ? "primary-button-color" : ""}
          >
            {" "}
            {option.label || option.text}{" "}
          </option>
        ),
        text: option.label || option.text,
        value: option.value
      }
    })
  }

  onChange = (event, syntheticEvent) =>
    this.props.onChange({ ...syntheticEvent, target: { value: syntheticEvent.value } })

  render() {
    const {
      className,
      disabled,
      placeholder,
      onChange,
      defaultValue,
      options,
      value,
      valueForTitle
    } = this.props

    return (
      <Dropdown
        onChange={this.onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        fluid
        selection
        disabled={disabled}
        value={value}
        search
        options={this.formattedOptionValues()}
        className={className}
        title={valueForTitle}
      ></Dropdown>
    )
  }
} // class Select

export default Select
