import React, { Component, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"
import { sortBy } from "lodash"
import { Segment, Header } from "semantic-ui-react"

import { formatCurrency } from "../../utilities/currency"
import CollapsibleList from "../../components/shared/CollapsibleList"
import MiniLoader from "../../components/shared/MiniLoader"
import { withTranslation } from "react-i18next"

const ApprovalItem = ({ item, t }) => {
  const actor = item.acting_user ? `${item.acting_user} - ` : ""
  const [showAllItems, setShowAllItems] = useState(false)

  return (
    <div key={`approval-item-${item.id}`} style={{ paddingBottom: "10px" }}>
      <strong>{`${moment(item.created_at).format("MM/DD/YY, h:mma")}: `}</strong>
      {`${actor}${item.activity_type} - ${formatCurrency(item.total)}`}

      {showAllItems &&
        sortBy(item.service_details, "name").map((detail) => {
          return (
            <div key={`approval-item-${item.id}-${detail.name}`} style={{ paddingLeft: "10px" }}>
              {`${detail.name} - ${formatCurrency(detail.price)}`}
            </div>
          )
        })}

      {(item.service_details || []).length > 0 && (
        <div
          className="link"
          onClick={() => setShowAllItems(!showAllItems)}
          style={{ paddingLeft: "10px" }}
        >
          {`${showAllItems ? t("hideLabel") : t("showLabel")}`} {t("detailsLabel")}
        </div>
      )}
    </div>
  )
}

class ApprovalsHistory extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    shopOrderId: PropTypes.number
  }

  componentDidMount() {
    const { dispatch, shopOrderId } = this.props

    dispatch({
      type: "SHOP_ORDER_ACTIVITY_ITEMS_LOAD_SAGA",
      payload: {
        shopOrderId: shopOrderId
      }
    })
  }

  render() {
    const { approvalLogItems, isLoading, t } = this.props

    return (
      <div style={{ padding: "20px" }}>
        <Header size="small" className={"dash-hd"}>
          {t("approvalHistoryLabel")}
        </Header>
        <Segment>
          {isLoading ? (
            <MiniLoader inverted={false} />
          ) : approvalLogItems.length === 0 ? (
            <em>{t("noApprovalsLabel")}</em>
          ) : (
            <CollapsibleList
              items={sortBy(approvalLogItems, "id")
                .reverse()
                .map((item) => (
                  <ApprovalItem item={item} t={t} />
                ))}
              applySort={false}
              t={t}
              approval_history={true}
            />
          )}
        </Segment>
      </div>
    )
  }
} // class ApprovalsHistory

const mapStateToProps = (state) => {
  const { shopOrderActivityItems } = state

  return {
    isLoading: shopOrderActivityItems.isLoading,
    approvalLogItems: shopOrderActivityItems.items
  }
}

export default connect(mapStateToProps)(withTranslation("approvalHistory")(ApprovalsHistory))
