import { getStyles } from "../components/shared/StylesheetInjector"
import moment from "moment"
import { Date_Format } from "../constants/years"
import { NA } from "../constants/vehicles"
const styles = getStyles()

export const PAST_DUE = "Past Due"
export const DUE_SOON = "Due Soon"
export const DUE_NOW = "Due Now"

function vehiclesWithoutOrdersWithMaintenance({ vehicles, serviceSchedulesByVehicle }) {
  return vehicles.filter(
    (v) => !v.active_order && vehicleHasMaintenance(v, serviceSchedulesByVehicle)
  )
}

export function pastDue({ vehicles, serviceSchedulesByVehicle }) {
  return vehiclesWithoutOrdersWithMaintenance({ vehicles, serviceSchedulesByVehicle })
    .map((v) => {
      let maint = findPastDueMaintenance(v, serviceSchedulesByVehicle)
      if (maint.length > 0) return combineMaintenanceWithVehicle(maint, v, PAST_DUE)
    })
    .filter((m) => m)
}

export function dueSoon({ vehicles, serviceSchedulesByVehicle }) {
  return vehiclesWithoutOrdersWithMaintenance({ vehicles, serviceSchedulesByVehicle })
    .map((v) => {
      let maint = findDueSoonMaintenance(v, serviceSchedulesByVehicle)
      if (maint.length > 0) return combineMaintenanceWithVehicle(maint, v, DUE_SOON)
    })
    .filter((m) => m)
}

function combineMaintenanceWithVehicle(maintenance, vehicle, type) {
  return { maintenance, vehicle, type }
}

export function dueStatusForVehicle(v, serviceSchedulesByVehicle) {
  if (vehicleHasMaintenance(v, serviceSchedulesByVehicle)) {
    if (vehicleMaintenancePastDue(v, serviceSchedulesByVehicle)) return "pastDueLabel"
    else if (vehicleMaintenanceDueSoon(v, serviceSchedulesByVehicle)) return "dueSoonLabel"
    else return null
  }
}

function vehicleHasMaintenance(v, serviceSchedulesByVehicle) {
  if (serviceSchedulesByVehicle[v.id] && serviceSchedulesByVehicle[v.id].length > 0) return true
}

function vehicleMaintenancePastDue(v, serviceSchedulesByVehicle) {
  const pastDueMaint = findPastDueMaintenance(v, serviceSchedulesByVehicle)
  if (pastDueMaint.length && pastDueMaint.length > 0) return true
}

function vehicleMaintenanceDueSoon(v, serviceSchedulesByVehicle) {
  const dueSoonMaint = findDueSoonMaintenance(v, serviceSchedulesByVehicle)
  if (dueSoonMaint.length && dueSoonMaint.length > 0) return true
}

function findPastDueMaintenance(v, serviceSchedulesByVehicle) {
  return serviceSchedulesByVehicle[v.id].filter((m) => m.recommendation_state == PAST_DUE)
}

function findDueSoonMaintenance(v, serviceSchedulesByVehicle) {
  return serviceSchedulesByVehicle[v.id].filter((m) =>
    [DUE_SOON, DUE_NOW].includes(m.recommendation_state)
  )
}

export function dueStatusColor(status) {
  if (status === "dueSoonLabel") return styles.dueSoonColor
  else if (status === "pastDueLabel") return styles.pastDueColor
  else return null
}

export function upcomingMaintenanceData({ vehicles, serviceSchedulesByVehicle }) {
  if (!serviceSchedulesByVehicle) return []

  let duped = pastDue({ vehicles, serviceSchedulesByVehicle }).concat(
    dueSoon({ vehicles, serviceSchedulesByVehicle })
  )
  let byId = {}
  duped.forEach((obj) => {
    if (!byId[obj.vehicle.id] || byId[obj.vehicle.id].length == 0) byId[obj.vehicle.id] = obj
  })
  let dedupedArr = []
  Object.keys(byId).map((id) => dedupedArr.push(byId[id]))

  return dedupedArr
}

export function earliestMaintenanceDate(maintenanceIntervals, isDomainFleetMaintenanceHubCanada) {
  let dates = maintenanceIntervals.map((maintInt) => maintInt.estimated_maintenance_date)
  dates = dates
    .filter((date) => date && date.length > 0)
    .sort()
    .map((date) => moment(date))
  return dates[0] && isDomainFleetMaintenanceHubCanada
    ? dates[0].format(Date_Format.YYYY_MM_DD)
    : dates[0].format("L")
}

export function getReminderDate(vehicleReminders, isDomainFleetMaintenanceHubCanada, vehicleId) {
  if (vehicleReminders.length) {
    const reminder = vehicleReminders.find((reminder) => reminder.vehicle_id === vehicleId)
    const reminderDate = reminder ? moment(reminder.reminder_date) : NA
    if (reminderDate === NA) {
      return NA
    }
    return isDomainFleetMaintenanceHubCanada
      ? reminderDate.format(Date_Format.YYYY_MM_DD)
      : reminderDate.format("L")
  } else {
    return NA
  }
}
