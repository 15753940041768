import * as vehicleHelpers from "../helpers/vehicleHelpers"
import * as shopHelpers from "../helpers/shopHelpers"
import { PAID_STATUSES, COMPLETED } from "./statuses"

import moment from "moment"
import { Date_Format } from "./years"
import { isFleetMaintenanceHubCanada } from "../helpers/affiliationHelpers"

export const ACTIVE = "active"
export const PAST = "past"
export const UPCOMING = "upcoming"
export const SCHEDULE_BUTTON_FIELD = "schedule_now"

export const MANUAL_MAINTENANCE_TYPE = "Manually Entered"
export const FLEETADVISE_MAINTENANCE_TYPE = "FleetAdvise"

export function activeOrderGridColumnDefs(
  page,
  servicesCellRenderer,
  schedulerCellRenderer,
  statusCellRenderer,
  t
) {
  const widths = {
    xsmall: 125,
    small: 150,
    medium: 175,
    large: 200,
    xlarge: 225,
    xxlarge: 250,
    xxxlarge: 300
  }

  let arr = [
    {
      headerName: t("fleets:fleetLabel"),
      field: "fleet_name",
      width: widths.xlarge
    },
    {
      headerName: t("vehicle:dateLabel"),
      field: "appointment_datetime",
      width: widths.small,
      filter: "agDateColumnFilter",
      valueFormatter: (data) =>
        isFleetMaintenanceHubCanada() && data.value
          ? data.value.format(Date_Format.YYYY_MM_DD)
          : data.value
          ? data.value.format("L")
          : ""
    },
    {
      headerName: t("dashboard:vehicleLabel"),
      field: "vehicle",
      width: widths.xlarge
    },
    {
      headerName: t("vehicle:vinLabel"),
      field: "vin",
      width: widths.large
    },
    {
      headerName: t("dashboard:otherIdLabel"),
      field: "other_id",
      width: widths.small
    },
    {
      headerName: t("dashboard:DepartmentNumber"),
      field: "department_number",
      width: widths.medium
    },
    {
      headerName: t("shops:shopLabel"),
      field: "shop",
      width: widths.xxlarge,
      resizable: true
    },
    {
      headerName: t("settings:servicesLabel"),
      field: "services",
      width: widths.xxxlarge,
      resizable: true,
      cellRendererFramework: servicesCellRenderer
    },
    {
      headerName: "PO #",
      field: "po_number",
      width: widths.medium
    },
    {
      headerName: t("vehicle:costLabel"),
      field: "total",
      width: widths.small,
      valueFormatter: (data) =>
        isFleetMaintenanceHubCanada() && data.value
          ? `$${data.value} CAD`
          : data.value
          ? `$${data.value}`
          : ""
    },
    {
      headerName: t("invoiceLabel"),
      field: "invoice_number",
      width: widths.medium
    },
    {
      headerName: t("maintenanceTypeLabel"),
      field: "maintenance_type",
      width: widths.medium
    }
  ]

  if (page !== PAST)
    arr.splice(2, 0, {
      headerName: t("timeLabel"),
      field: "time",
      width: widths.xsmall
    })

  arr.splice(1, 0, {
    headerName: t("statusLabel"),
    field: "status",
    width: widths.small,
    cellRendererFramework: statusCellRenderer
  })

  return arr
}

export function activeOrderGridRowData(shopOrders, language) {
  return shopOrders.map((order) => {
    const vehicle = order.vehicle
    return {
      appointment_datetime: moment(order.appointment_datetime).startOf("day"),
      vehicle: vehicleHelpers.formattedName(vehicle),
      shop: shopHelpers.formattedName(order.shop),
      other_id: vehicle.other_id || "N/A",
      department_number: vehicle.department_number || "N/A",
      po_number: order.po_number,
      status: {
        status: PAID_STATUSES.indexOf(order.status) === -1 ? order.status : COMPLETED,
        id: order.id
      },
      time: order.appointment_time_pretty,
      invoice_number: order.invoice_number,
      services: order.order_services
        .filter((os) => os.status !== "rejected" && os.status !== "deleted")
        .map((s) => `${s.name}${s.position ? ` (${s.position})` : ""}`)
        .join(", "),
      total: order.total && parseFloat(order.total).toFixed(2),
      // NOTE: The ID column does not get displayed because there is no mapping for it in the columnDefs, however, providing here makes it available to the row's data attribute.
      id: order.id,
      maintenance_type: order.maintenance_type || FLEETADVISE_MAINTENANCE_TYPE,
      vin: vehicle.vin,
      vehicle_id: vehicle.id,
      fleet_name: order.fleet_name,
      language
    }
  })
}
