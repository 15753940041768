import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import MaintenanceHistoryRecord from "./MaintenanceHistoryRecord"
import { Button, Dimmer, Loader, Table } from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"

class MaintenanceHistory extends Component {
  static propTypes = {
    vehicle: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props)
    this.state = {
      pageMH: 1, //MH ==> Maintenance History
      pageMMH: 1, //MH ==> Manual Maintenance History,
      isSeeMoreEnabled: true,
      isLoading: this.props.isVehicleMHComplete ? false : true
    }
  }

  componentDidMount() {
    if (!this.props.isVehicleMHComplete) {
      this.handleMoreButtonClick()
    }
  }

  handleMoreButtonClick = () => {
    const { dispatch, vehicle } = this.props
    const { pageMH, pageMMH, prevPageMH, prevPageMMH } = this.state
    const payload = {}
    payload.pageMH = prevPageMH !== pageMH ? pageMH : null
    payload.pageMMH = prevPageMMH !== pageMMH ? pageMMH : null
    payload.vehicleId = vehicle.id
    this.setState({ isLoading: true })
    dispatch({
      type: "MAINTENANCE_HISTORY_LOAD_SAGA",
      payload,
      callback: this.afterMaintenanceHistoryLoad
    })
  }

  afterMaintenanceHistoryLoad = (totalPageMMH, totalPageMH) => {
    const { pageMH, pageMMH } = this.state
    let currentPageMH = pageMH
    let currentPageMMH = pageMMH
    if (pageMH < totalPageMH) {
      currentPageMH += 1
    }
    if (pageMMH < totalPageMMH) {
      currentPageMMH += 1
    }
    if (pageMH !== currentPageMH || pageMMH !== currentPageMMH) {
      this.setState({
        pageMH: currentPageMH,
        pageMMH: currentPageMMH,
        prevPageMH: pageMH,
        prevPageMMH: pageMMH,
        isLoading: false
      })
    } else {
      this.setState({ isSeeMoreEnabled: false, isLoading: false })
      this.props.dispatch({
        type: "VEHICLE_MAINTENANCE_HISTORIES_LOAD_COMPLETE"
      })
    }
  }

  renderHistoryRecords() {
    const { maintenance_histories } = this.props

    return maintenance_histories.map((record, index) => {
      return <MaintenanceHistoryRecord key={index} record={record} {...this.props} />
    })
  }

  render() {
    const { t, isVehicleMHComplete } = this.props
    const { isSeeMoreEnabled, isLoading } = this.state
    if (isLoading) {
      return (
        <Dimmer active inverted style={{ padding: 0 }}>
          <Loader indeterminate />
        </Dimmer>
      )
    }
    return (
      <React.Fragment>
        <Table className="borderless">
          <Table.Header>
            <Table.Row>
              {[
                t("dateLabel"),
                t("shopLabel"),
                isFleetMaintenanceHubCanada() ? t("dashboard:kilometersLabel") : "Miles",
                t("completedServicesLabel"),
                t("savedServicesLabel"),
                t("costLabel"),
                ""
              ].map((s) => (
                <Table.HeaderCell singleLine>{s}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderHistoryRecords()}</Table.Body>
        </Table>
        <Button
          onClick={this.handleMoreButtonClick}
          disabled={!isSeeMoreEnabled || isVehicleMHComplete}
        >
          See More
        </Button>
      </React.Fragment>
    )
  }
} // class MaintenanceHistory

const mapStateToProps = (state) => {
  const { vehicle, isVehicleMHComplete } = state.vehicles

  return {
    vehicle: vehicle,
    currentUser: state.users.currentUser,
    maintenance_histories: (vehicle.maintenance_histories || []).concat(
      vehicle.manualMaintenanceHistories || []
    ),
    isVehicleMHComplete
  }
}

export default connect(mapStateToProps)(withTranslation("vehicle")(MaintenanceHistory))
