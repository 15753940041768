import React from "react"
import { Input, Grid, Button, Checkbox } from "semantic-ui-react"
import VehicleModal from "./VehicleModal"
import BulkUploadModal from "../../components/shared/BulkUploadModal"
import { useTranslation } from "react-i18next"
import { isFleetMaintenanceHubCanada, isFleetcor } from "../../helpers/affiliationHelpers"

export default function VehicleSearch({
  debouncedOnFilterChange,
  resetFiltering,
  loadVehicles,
  sizeToFit,
  debouncedOnSizeToFitChange,
  dispatch,
  language,
  currentUser,
  vehicles = []
}) {
  const { t } = useTranslation("vehicleIndex")

  const handleDownloadClick = async () => {
    await dispatch({
      type: "VEHICLES_LOAD_SAGA",
      payload: {
        download: true,
        language: language,
        isDomainShellCanada: isFleetMaintenanceHubCanada()
      }
    })
  }

  return (
    <Grid columns="equal" className={"ag-theme-search"} stackable>
      <Grid.Column textAlign="left">
        <Grid stackable>
          <Grid.Column width={3}>
            <Input
              className="search"
              onChange={debouncedOnFilterChange}
              icon="search"
              placeholder={t("searchVechilesLabel")}
            />
          </Grid.Column>
          <Grid.Column width={3} style={{ paddingLeft: "65px" }}>
            <Checkbox
              label={t("sizeToFitLabel")}
              onChange={debouncedOnSizeToFitChange}
              checked={sizeToFit}
            />
          </Grid.Column>
          <Grid.Column width={10} textAlign="right">
            {
              <Button className="white-button" onClick={resetFiltering}>
                {t("resetFiltersLabel")}
              </Button>
            }
            <>
              &nbsp;
              {isFleetcor() ? (
                <BulkUploadModal completionCallback={loadVehicles}>
                  <Button style={{ borderRadius: "6px !important", marginLeft: "5px" }}>
                    {t("bulkUpdateLabel")}
                  </Button>
                </BulkUploadModal>
              ) : (
                <Button.Group>
                  <VehicleModal completionCallback={loadVehicles}>
                    <Button> {t("addVehicleLabel")}</Button>
                  </VehicleModal>
                  <Button.Or text={t("orLabel")} />
                  <BulkUploadModal completionCallback={loadVehicles}>
                    <Button className="last-button">{t("bulkUploadLabel")}</Button>
                  </BulkUploadModal>
                </Button.Group>
              )}
            </>
            &nbsp;
            <Button onClick={handleDownloadClick} disabled={vehicles.length === 0}>
              {t("services:downloadLabel")}
            </Button>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </Grid>
  )
}
