import { getSubdomain } from "./components/shared/StylesheetInjector"

export default {
  core: {
    authorizationKey: process.env.REACT_APP_BROWSER_API_KEY,
    key: "key"
  }
}
export const ATTRIBUTES = {
  affiliations: getSubdomain()
}

//treatments constants
export const ON = "on"
export const OFF = "off"

//splits names constants
export const GLOVEBOX_FEATURE_NAME = "Glovebox_Feature"
export const MR_REMIND_ME_FEATURE = "MR_Remind_Me_Feature"
