import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import EditModal from "../shared/EditModal"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import * as userHelpers from "../../helpers/userHelpers"
import { getSubdomain } from "../shared/StylesheetInjector"
import { isFleetcor } from "../../helpers/affiliationHelpers"
import {
  FMC,
  FMC_FLEET_ADMIN,
  FMC_FLEET_MANAGER,
  ADMIN,
  OWNER,
  DRIVER,
  MANAGER,
  FLEET_MANAGER,
  ACCOUNT_OWNER
} from "../../constants/roles"
import { sortBy } from "lodash"
import { withTranslation } from "react-i18next"
import { isFMCUser } from "../../helpers/userRoleHelpers"
import { List } from "semantic-ui-react"
import { FLEET } from "../../constants/application"

const page_condition = window.location.href.indexOf("settings")

class UserModal extends Component {
  allFields = () => {
    const { isFMC, t, isFleetMode } = this.props
    const rolesHelper = (
      <List
        items={[
          "Owner: Owner of the Fleet.",
          "Admin: Administrator of the Fleet. Add users, vehicles and set policies.",
          "Driver: Fleet Driver. Can view assigned vehicle.",
          "Manager: Manager of the Fleet. Can set policies and assign drivers."
        ]}
      />
    )
    const fmcRolesHelper = (
      <List
        items={[
          "FMC: Owner of the Fleet.",
          "FMC Admin: Administrator of the Fleet. Add users, vehicles and set policies.",
          "FMC Manager: Manager of the Fleet. Can set policies and assign drivers."
        ]}
      />
    )
    const approvalFleetsHelper = (
      <div>
        When a vehicle gets service that exceeds dollar amounts set by policies, an FMC may need to
        approve. Select which fleets this user should be notified about.
      </div>
    )

    let opts = {
      1: [
        { placeholder: t("firstNameLabel"), optional: false, fieldName: "firstName" },
        { placeholder: t("lastNameLabel"), optional: false, fieldName: "lastName" },
        {
          placeholder: t("emailLabel"),
          optional: false,
          fieldName: "email",
          specialValidations: ["email"]
        },
        {
          placeholder: t("phoneNumberLabel"),
          optional: false,
          fieldName: "cellPhone",
          specialValidations: ["phone"]
        }
      ]
    }
    if (!isFMC) {
      opts[1].push({
        placeholder: t("rolesLabel"),
        optional: true,
        fieldName: "roles",
        valueSet: "userRoles",
        helpModal: rolesHelper
      })
      opts[1].push({
        placeholder: t("fleetsLabel"),
        optional: true,
        fieldName: "approvalFleetIds",
        valueSet: "fleets",
        helpModal: approvalFleetsHelper
      })
      opts[2] = [
        {
          placeholder: <strong>{t("textPreferencesLabel")}</strong>,
          optional: true,
          fieldName: "text_notification_preference_attributes"
        }
      ]

      opts[3] = [
        {
          placeholder: <strong>{t("emailPreferencesLabel")}</strong>,
          optional: true,
          fieldName: "email_notification_preference_attributes"
        }
      ]
    }
    if (isFMC) {
      opts[1].push({
        placeholder: t("rolesLabel"),
        fieldName: "roles",
        valueSet: "userRoles",
        helpModal: fmcRolesHelper
      })
      opts[1].push({
        placeholder: t("fleetsLabel"),
        optional: true,
        fieldName: "approvalFleetIds",
        valueSet: "fleets",
        helpModal: approvalFleetsHelper
      })
    }
    if (isFleetMode) {
      opts[1].push({
        placeholder: t("vehiclesLabel"),
        optional: true,
        fieldName: "vehicle_ids",
        valueSet: "vehicles"
      })
      opts[2] = [
        {
          placeholder: <strong>{t("textPreferencesLabel")}</strong>,
          optional: true,
          fieldName: "text_notification_preference_attributes"
        }
      ]
      opts[3] = [
        {
          placeholder: <strong>{t("emailPreferencesLabel")}</strong>,
          optional: true,
          fieldName: "email_notification_preference_attributes"
        }
      ]
    }

    return opts
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    completionCallback: PropTypes.func.isRequired
  }

  static defaultProps = {
    user: {},
    completionCallback: () => {}
  }

  isFleetAmerica = () => {
    const sub = getSubdomain()
    return sub == "fleetamerica"
  }

  getNewFormState = (user) => {
    let textNotificationPreference
    let emailNotificationPreference
    let defaultTextNotificationPreference = userHelpers.DEFAULT_TEXT_NOTIFICATION_PREFERENCE
    let defaultEmailNotificationPreference = userHelpers.DEFAULT_EMAIL_NOTIFICATION_PREFERENCE

    const { isFMC, currentUser } = this.props

    if (this.isFleetAmerica()) {
      delete defaultTextNotificationPreference.one_hour_reminder
    }

    if (user.text_notification_preference) {
      if (this.isFleetAmerica()) {
        delete user.text_notification_preference.one_hour_reminder
      }
      textNotificationPreference = { ...user.text_notification_preference }
      userHelpers.mapPreferencesToDefaults(
        textNotificationPreference,
        defaultTextNotificationPreference
      )
    }

    if (user.email_notification_preference) {
      emailNotificationPreference = { ...user.email_notification_preference }
      userHelpers.mapPreferencesToDefaults(
        emailNotificationPreference,
        defaultEmailNotificationPreference
      )
    }

    let roles = (user.roles || []).map((r) => (typeof r === "string" ? r : r.name))

    let newFormState = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      cellPhone: user.cellPhone,
      other_id: user.other_id,
      roles: roles,
      vehicle_ids: user.vehicles
        ? user.vehicles.filter((v) => v.fleet_id == currentUser.fleet_id).map((v) => v.id)
        : [],
      approvalFleetIds: (user.approval_fleets || []).map((fleet) => fleet.id),
      payment_id: user.payment_id,
      text_notification_preference_attributes:
        textNotificationPreference || defaultTextNotificationPreference,
      email_notification_preference_attributes:
        emailNotificationPreference || defaultEmailNotificationPreference
    }
    if (isFMC) {
      newFormState = {
        ...newFormState,
        affiliationId: currentUser && currentUser.affiliation_id
      }
    }

    return newFormState
  }

  render() {
    var { user, userRoles, currentUser } = this.props
    const roles = [FMC_FLEET_ADMIN, FMC, FMC_FLEET_MANAGER]
    const without_fmc_roles = [ADMIN, OWNER, FMC]
    const fleetcor_roles = [
      ACCOUNT_OWNER,
      FLEET_MANAGER,
      DRIVER,
      FMC_FLEET_ADMIN,
      FMC,
      FMC_FLEET_MANAGER
    ]
    userRoles = userRoles.filter((value) => value == "FMC")
    return (
      <>
        <EditModal
          objectName="User"
          object={user}
          getNewFormState={this.getNewFormState}
          allFields={this.allFields()}
          editFields={this.allFields()}
          saga={"USER_CREATE_OR_UPDATE_SAGA"}
          {...this.props}
        />
      </>
    )
  }
} // class UserModal

const mapStateToProps = (state, ownProps) => {
  var { userRoles } = state.users
  const { vehicles } = state.vehicles
  var { fleets } = state.fleets
  if (fleets.length <= 0) {
    var fleet = state.users.users
      .map((t) => {
        return t.approval_fleets
      })
      .flat()
    var unique = [...new Map(fleet.map((item) => [item["id"], item])).values()]
    fleets = unique
  }
  if (userRoles.length > 0 && !ownProps.isFMC) {
    userRoles = userRoles.filter(
      (role) =>
        role.name === ADMIN ||
        role.name === OWNER ||
        role.name === MANAGER ||
        role.name === DRIVER ||
        role.name === FLEET_MANAGER ||
        role.name === ACCOUNT_OWNER
    )
  }

  return {
    userRoles: userRoles.map((role) => ({ key: role.id, value: role.name, text: role.name })) || [],
    vehicles: vehicleHelpers.selectOptions(vehicles, { semantic: true }),
    fleets: sortBy(fleets, "name").map((fleet) => {
      return { key: fleet.id, value: fleet.id, text: fleet.name }
    }),
    isFleetMode: state.application.mode === FLEET
  }
}

export default connect(mapStateToProps)(withTranslation("common")(UserModal))
