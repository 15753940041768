import React, { Component } from "react"
import PropTypes from "prop-types"
import Button from "../../elements/Button"
import Select from "../../elements/Select"
import { Segment, Grid } from "semantic-ui-react"
import { formatCurrency, PRICED_AT_SHOP } from "../../utilities/currency"
import MiniLoader from "../shared/MiniLoader"
import { withTranslation } from "react-i18next"
import { MD_HD_VEHICLE_TYPE_ID } from "../../constants/application"

class SelectedService extends Component {
  static propTypes = {
    onRemoval: PropTypes.func.isRequired,
    onServiceOptionChange: PropTypes.func.isRequired,
    service: PropTypes.object.isRequired
  }

  positionSelectOptions() {
    const { service } = this.props

    const positionOptions = service.positionOptions || []

    return positionOptions.map((position) => {
      return {
        label: position,
        value: position
      }
    })
  }

  renderPositionsSelect() {
    const { onServiceOptionChange, service } = this.props

    // NOTE: Placeholder is "Select Option", rather than "Select Position", because certain services, such as "Oil Change", store non-position options, such as the type of oil, in the positions field.
    if (service.hasOwnProperty("position")) {
      return (
        <Segment className={"service_request__options_select_container"}>
          <Select
            className={"service_request__options_select"}
            onChange={(event) => onServiceOptionChange(service.id, "position", event.target.value)}
            options={this.positionSelectOptions()}
            placeholder={"Select Option"}
            value={service.position}
            defaultValue={service.position}
          />
        </Segment>
      )
    } else {
      return null
    }
  }

  render() {
    const { onRemoval, service, price, servicePricesLoading, t, vehicle_type_id } = this.props

    let servicePrice
    if (vehicle_type_id === MD_HD_VEHICLE_TYPE_ID) {
      servicePrice = t("priceAtShopLbl")
    } else {
      if (price && price.price_estimates) {
        if (service.position) {
          servicePrice = (
            price.price_estimates.find((pe) => pe.position === service.position) || {}
          ).shop_price_estimate
        } else if (service.positionOptions && !service.positionOptions[0]) {
          servicePrice = (price.price_estimates[0] || {}).shop_price_estimate
        }
        servicePrice = servicePrice ? formatCurrency(servicePrice) : t("priceAtShopLbl")
      }
    }

    return (
      <Segment.Group style={{ marginBottom: "10px" }}>
        <Segment>
          <Grid columns="equal">
            <Grid.Column>
              <div>{service.name}</div>
              {servicePricesLoading ? (
                <MiniLoader inverted={false} />
              ) : (
                <div className="approvals_form_field__price_shop">{servicePrice}</div>
              )}
            </Grid.Column>

            <Grid.Column textAlign="right">
              <Button label={t("removeLabel")} onClick={() => onRemoval(service.id)} />
            </Grid.Column>
          </Grid>
        </Segment>

        {this.renderPositionsSelect()}
      </Segment.Group>
    )
  }
} // class SelectedService

export default withTranslation("common")(SelectedService)
